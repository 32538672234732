module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"titleTemplate":"LEDpac LLC | %s","language":"en","openGraph":{"type":"website","locale":"en_US","url":"https://www.ledpac.com","site_name":"SiteName"},"twitter":{"handle":"@handle","site":"@site","cardType":"summary_large_image"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-mailgo/gatsby-browser.js'),
      options: {"plugins":[],"mailgoConfig":{"dark":true,"showFooter":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"LEDpac LED Lighting Solutions","short_name":"LEDpac","lang":"en","start_url":"/","background_color":"#000000","theme_color":"#ff0000","display":"minimal-ui","icon":"static/logo/Logo-dark.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4bf0534870d46c9363f6099de4d51629"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
